@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Maps InfoWindow fixes */
.gm-style .gm-style-iw-c {
  padding: 0 !important;
  max-width: none !important;
  border-radius: 8px !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  padding: 0 !important;
  max-width: none !important;
}

.pr-8 {
    padding-right: 0;
}

/* Position the close button properly */
.gm-style .gm-style-iw-t button.gm-ui-hover-effect {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  width: 24px !important;
  height: 24px !important;
  opacity: 1 !important;
  padding: 2px !important;
  margin: 8px !important;
  z-index: 10 !important;
}

/* Remove the default InfoWindow tail/arrow */
.gm-style .gm-style-iw-t::after {
  display: none !important;
}

/* Remove any background color from the outer container */
.gm-style-iw.gm-style-iw-c > div:first-child {
  display: none !important;
}

.gm-ui-hover-effect {
  opacity: 1 !important;
  background: #fff !important;
  padding: 2px !important;
  border-radius: 50% !important;
  margin: 8px 8px 0 0 !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2) !important;
}

.gm-ui-hover-effect img {
  width: 16px !important;
  height: 16px !important;
}

.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  padding: 0 !important;
}

/* Adjust the content wrapper to account for the close button */
.gm-style .gm-style-iw-tc {
  z-index: 0 !important;
}


